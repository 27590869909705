
















import FilterFabric from '@/views/chat/filters/Filters/FilterFabric'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { FilterConfig, FilterTypes } from '@/views/chat/filters/FilterTypes'
import { InputSetups } from '../../../mixins/input-setups'
import PageTitle from '@/components/PageTitle.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    CenteredColumnLayout,
    PageTitle,
  },
})
export default class WarningSystem extends Mixins(UseFields, InputSetups) {
  filterConfig: FilterConfig | null = null

  created(): void {
    this.filterConfig = FilterFabric.create(FilterTypes.Warn)
  }
}
